export const RecordingToken = "RecordingToken";
export const Email = "Email";
export const FullName = "FullName";
export const AffiliateId = "AffiliateId";


export function SetRecordingToken(
  token: string, 
  email: string, 
  fullName: string,
   affiliateId: string) {
  localStorage.setItem(RecordingToken, token);
  localStorage.setItem(Email, email);
  localStorage.setItem(FullName, fullName);
  localStorage.setItem(AffiliateId, affiliateId);
}

export function GetRecordingToken() {
  return localStorage.getItem(RecordingToken);
}

export function GetEmail() {
  return localStorage.getItem(Email);
}

export function GetName() {
  return localStorage.getItem(FullName);
}

export function GetAffiliateId() {
  return localStorage.getItem(AffiliateId);
}